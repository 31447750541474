
import { computed, defineComponent, onMounted, ref, useSlots } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import { useForm } from "vee-validate";

interface HirePurchase {
  existingBalance: string;
  loanPeriod: number;
  interestRate: number;
}

export default defineComponent({
  name: "hire-purchase",
  components: {
    ErrorMessage,
    Field,
    Form,
    ElCurrencyInput,
  },
  data() {
    const route = useRoute();
    const submitButton1 = ref<HTMLElement | null>(null);
    const invProjCalculated = false;

    const yearlyPlanSchedule = {
      year: "",
      existingBalance: 0,
      contribution: 0,
      dividend: 0,
      futureBalance: 0,
    };

    const plan = [];

    return {
      id: route.params.id ?? null,
      store: useStore(),
      submitButton1,
      invProj: {
        calculator_type_id: 11,
        raw: {
          existingBalance: 0,
          annualSalary: 120000,
          currAnnualEPF: 27600,
          growthRateOfSalary: 4,
          rateOfDividend: 5,
          rateOfContributionToEpf: 23,
          yearsToRetirement: 40,
        },
      },
      yearlyPlanSchedule,
      plan,
      invProjCalculated,
    };
  },
  setup() {
    const { t, te, n } = useI18n();

    const investmentValidator = Yup.object({
      raw: Yup.object({
        existingBalance: Yup.string().required().label("Existing EPF balance"),
        annualSalary: Yup.string().required().label("Annual Salary"),
        currAnnualEPF: Yup.string().required().label("Current Annual EPF"),
        growthRateOfSalary: Yup.number()
          .required()
          .label("Growth rate of salary "),
        rateOfDividend: Yup.number().required().label("Rate of Dividend "),
        rateOfContributionToEpf: Yup.number()
          .required()
          .label("Rate of contribution to EPF "),
      }),
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Future Retirement Savings Projection", [
        "Financial Calculators",
      ]);
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    const handleClick = (tab: string, event: Event) => {
      console.log(tab, event);
    };

    return {
      investmentValidator,
      translate,
      nFormat,
      handleClick,
    };
  },
  methods: {
    init() {
      if (this.id) {
        this.store.dispatch(Actions.GET_CALCULATOR, this.id).then(() => {
          this.invProj = this.store.getters.getCalculatorData.raw;
          this.calculate();
        });
      }
    },
    calcAnnualEPF() {
      this.invProj.raw.currAnnualEPF =
        (this.invProj.raw.annualSalary *
          this.invProj.raw.rateOfContributionToEpf) /
        100;
    },
    calculate() {
      this.plan = [];
      let growthRateOfSalary = this.invProj.raw.growthRateOfSalary / 100;
      let rateOfDividend = this.invProj.raw.rateOfDividend / 100;
      let rateOfContributionToEpf =
        this.invProj.raw.rateOfContributionToEpf / 100;

      let existingBalance = this.invProj.raw.existingBalance;
      let contribution = this.invProj.raw.currAnnualEPF;
      let dividend = existingBalance * rateOfDividend;
      let futureBalance = existingBalance + contribution + dividend;
      let year = this.ordinal_suffix_of(1);

      this.yearlyPlanSchedule = {
        year: year,
        existingBalance: existingBalance,
        contribution: contribution,
        dividend: dividend,
        futureBalance: futureBalance,
      };

      this.plan.push(this.yearlyPlanSchedule);
      for (let i = 1; i < this.invProj.raw.yearsToRetirement; i++) {
        let year = this.ordinal_suffix_of(i + 1);
        existingBalance = futureBalance;
        contribution = contribution * (1 + growthRateOfSalary);
        dividend = existingBalance * rateOfDividend;
        futureBalance = existingBalance + contribution + dividend;

        this.yearlyPlanSchedule = {
          year: year,
          existingBalance: existingBalance,
          contribution: contribution,
          dividend: dividend,
          futureBalance: futureBalance,
        };
        this.plan.push(this.yearlyPlanSchedule);
      }

      // console.log(this.plan);
      this.invProjCalculated = true;
    },
    ordinal_suffix_of(i) {
      var j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    },

    onSubmit(values) {
      console.log(values);
      this.store.dispatch(Actions.STORE_CALCULATOR, values).then(() => {
        Swal.fire({
          title: "Congratulation!",
          text: "Calculator has been saved!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      });
    },
  },
  async mounted() {
    this.init();
  },
});
